<template>
  <div class="container-fluid blog-container bg-blog">
    
    

    <section class="row" id="topo">
      <div class="col-sm-9 px-lg-5" style="background-color:rgba(68,13,69,0.95)">
<br>
         <h3 class="txt-titulo-branco bold" >Blog</h3>
 <p class="txt-white paragrafo-blog">
Seja bem-vindo ao blog dos Tarimbados, onde exploramos com mais detalhes as técnicas, dicas, truques e sugestões que fazem a diferença na cozinha.
</p>

<h3 class="txt-white" v-if="marcador">Filtrando por: "{{marcador}}"</h3>
 <button v-if="marcador || palavra != ''" @click="desfazerFiltro" style="max-width:300px !important"  class="btn btn-light btn-block btn-search-ingredients bold">Desfazer Filtro</button>

<br>

<div class="box-blog" v-for="(item,index) in resultados" :key="'blog'+index">
<hr style="border-color:#f18800;">
<p class="txt-white paragrafo-blog">{{item.AV_DATA}}</p>
<h2 class="txt-white">{{item.AV_NOME}}</h2>
<p class="txt-white ">{{item.AV_RESUMO}} [...]</p>
<router-link :to="'/postagem/'+item.AV_SLOGAN" class="btn-ler float-right px-2">Continuar Lendo...</router-link>
<div class="clearfix"></div>
</div><!-- box -->

<br>


<div v-if="showLoading" class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

  <p v-if="semResultados" class="text-center bold txt-white">Sem Resultados...</p>
 <div class="d-flex justify-content-center">
 
        <button v-if="semResultados == false" @click="paginar" style="width:300px;" class="btn btn-light btn-block btn-search-ingredients bold">CARREGAR MAIS POSTAGENS</button>
         </div>
        
        <br><br>
        
        </div><!-- blog -->

        <div class="col-sm-3" style="background-color:rgba(241,136,0,0.95)">
          <br>

          <Anuncio :id="8" padrao="/img/blog_anuncio.jpg" class="d-none d-sm-block" :mobile="false" />
           <Anuncio :id="8" padrao="/img/blog_anuncio.jpg" class="d-sm-none" :mobile="true" />

           <br>

<h3 class="txt-titulo-branco bold" >Busca por palavra</h3>
<div class="input-group mb-3" >
                 

                    <input type="text" v-model="palavra" class="form-control input-busca">
                    <div class="input-group-append" style="cursor:pointer;">
                      <span @click="buscarPalavra"  class="input-group-text"> <img  src="/img/magnifying-glass-4x.png"  alt="Lupa" /></span>
                    </div>
                  </div>


<br>

<h3 class="txt-titulo-branco bold" >Marcadores</h3>
          <ul>
            <li v-scroll-to="'#topo'" @click="filtrar(item.CB_NOME)" style="cursor:pointer;color:#FFF;" v-for="(item,index) in marcadores" :key="'marcador'+index">{{item.CB_NOME}}</li>
          </ul>
        
        </div><!-- pesquisa -->
    </section>







  </div>
</template>

<style>
.bg-blog{background:url('/img/background.png');background-size: cover;background-repeat: no-repeat;}
.txt-titulo-branco{color:#FFF;font-weight:bold;}
.txt-white{color:#FFF;}
.paragrafo-blog{font-size:1.5em;}
.btn-ler {
    background-color: #f18800;
    border: none;
    color: #fff;
    max-width:300px !important;
}
</style>

<script>
import servidor from '@/servidor'
import Anuncio from '@/components/Anuncio'

export default {
  name: 'Blog',
  metaInfo: {
    title: 'Blog - Tarimba na cozinha',
    meta:[{name: 'description', 
    content: "Tarimba Na Cozinha - blog. Seja bem-vindo ao blog dos Tarimbados, onde exploramos com mais detalhes as técnicas, dicas, truques e sugestões que fazem a diferença na cozinha."}]
  },
  components: {Anuncio},
  data(){
    return {
      semResultados:false,
      showLoading:false,
      texto:'',
      resultados:[],
      marcadores:[],
      marcador:null,
      palavra:''
  
    }
  },
  computed:{
    getUsuario(){
      return this.$store.state.usuario.id;
    }

  },
  methods:{
    desfazerFiltro(){
      this.resultados = [];
      this.marcador = null,
      this.palavra = '';
      this.semResultados = false;
      this.getBlog();
    },
    filtrar(item){
      this.marcador = item;
      this.resultados = [];
       this.semResultados = false;
       this.getBlog();
    },
      getMarcadores(){
      fetch(servidor+'gerenciaBlog/getCategorias/')
          .then((response) => response.json())
          .then((data) => {
             data.forEach(element => {
                this.marcadores.push(element);
            });
          });

    },
   
    getBlog(){
    this.showLoading = true;
    let filtros = new FormData();
    filtros.append('paginacao',0);
    if(this.marcador != null){
      filtros.append('marcador',this.marcador);
    }

    if(this.palavra != ''){
      filtros.append('palavra',this.palavra);
    }
    
      fetch(servidor+'gerenciaBlog/getBlog/',{method:"POST", body: filtros})
          .then((response) => response.json())
          .then((data) => {
            if(data == null || data.length == 0){ this.semResultados = true;}
            this.showLoading = false;
             data.forEach(element => {
                this.resultados.push(element);
            });
          });

    },
     paginar(){
       this.semResultados = false;
       this.showLoading = true;
       let filtros = new FormData();
       filtros.append('paginacao',this.resultados.length);
       if(this.marcador != null){
          filtros.append('marcador',this.marcador);
        }

      if(this.palavra != ''){
        filtros.append('palavra',this.palavra);
      }

       fetch(servidor+'gerenciaBlog/getBlog/',{method:"POST", body: filtros})
          .then((response) => response.json())
          .then((data) => {
             if(data == null || data.length == 0){ this.semResultados = true;}
            data.forEach(element => {
                this.resultados.push(element);
            });
            this.showLoading = false;
           
          });

    },
    buscarPalavra(){
      this.resultados = [];
      this.semResultados = false;
      this.marcador = null;
      this.getBlog();
    }
   
  
  },
  mounted(){
    if(this.$store.state.buscaBlog != ''){
      this.palavra = this.$store.state.buscaBlog;
      this.$store.commit('mudarPalavra','');
    }
    if(this.$store.state.marcadorBlog != ''){
      this.marcador = this.$store.state.marcadorBlog;
      this.$store.commit('mudarMarcador','');
      
    }

    this.getBlog();
    this.getMarcadores();
    this.$scrollTo("#topo");
  }
}
</script>
